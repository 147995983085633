.badge-box-container{
    margin-top: 1em;
    width: 100%;
}

.badge-box-container .badge-box-scroll-container{
    border-top: 3px solid var(--blue-div-border-color);
    border-bottom: 3px solid var(--blue-div-border-color);
    background-color: var(--background-blue-div-color);

    padding: 2em 0;
    overflow-x: auto;
}

.badge-box-container .badge-box-scroll-container .box-scroll{
    gap: 2em;
    margin: auto;
    padding: 0 1em;
    width: fit-content;
    justify-content: center;
    display: flex;
}

.badge-box-container .badge-box-scroll-container .box-scroll .box-scroll-badge{
    width: 10rem;
    min-height: 10rem;
}

.badge-box-container .badge-box-legend{
    margin: auto;
    margin-top: 1em;
    border-radius: 50px;

    display: flex;

    flex-direction: row-reverse;

    justify-content: space-evenly;

    border-radius: 50px;
    border: 2px solid var(--blue-div-border-color);
    background-color: var(--background-blue-div-color);

    width: min(80%, 40rem);
}

.badge-box-container .badge-box-legend .legend-item{

    width: 25%;
    font-size: 1.6rem;

    padding: 0.5em 0.5em;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 0.25em;
}

.badge-box-container .badge-box-legend .legend-item .level-square{
    border: 1px solid var(--league-gold-color);
    border-radius: 5px;

    width: max(25%, 2rem);
    aspect-ratio: 1/1;
}

.legend-item .level-name{
    font-size: 1.4rem;
    color: var(--league-white-color);
    text-shadow: 0 0 1px black, 0 0 1px black;
}

.badge-box-description{
    font-size: 2rem;
    margin: auto;
    margin-top: 1em;
    padding: 1em 1.5em;
    color: rgb(177, 176, 176);
    text-shadow: 0 0 1px black, 0 0 1px black;

    background-color: #020e1e2e;

    max-width: 45ch;
}

.badge-box-container .excellent{
    color: var(--excellent-color);
}

.badge-box-container .great{
    color: var(--great-color);
}

.badge-box-container .good{
    color: var(--good-color);
}

@media screen and (min-width: 75em){
    .badge-box-container .badge-box-scroll-container{
        border: 3px solid var(--blue-div-border-color);
        border-radius: 50px;
        background-color: var(--background-blue-div-color);
        margin: auto;
        max-width: 450px;
    }

    .badge-box-container .badge-box-scroll-container .box-scroll{
        flex-wrap: wrap;
        max-height: 340px;
        overflow-y: auto;
    }

    .legend-item .level-name{
        font-size: 2rem;
    }

    .badge-box-description{
        text-shadow: 0 0 2px black, 0 0 2px black;
    }

    ::-webkit-scrollbar {
        width: 15px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 6px #bf860078;
        transition-duration: 0.5s;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #bf8600; 
    }
}