.badge-container{
    width: 100%;
    height: 100%;

    border: 1px var(--league-gold-color);
    border-style: solid;
    border-radius: 10px;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.badge-unearned{
    filter: grayscale(100%);
    opacity: 25%;
}

.badge-container .img-container{
    object-fit: scale-down;
    width: 70%;
    aspect-ratio: 1/1;
}

.badge-container .badge-name{
    font-size: 1.2rem;
    object-fit: scale-down;
    color: var(--league-gold-color);
    white-space: nowrap;
    text-shadow: 0 0 2px black, 0 0 2px black;
    pointer-events: none;
}

.Good{
    background-color: var(--good-color);
}

.Great{
    background-color: var(--great-color);
}

.Excellent{
    background-color: var(--excellent-color);
}

.GoodText{
    display: inline;
    color: rgb(50, 56, 54);
}

.GreatText{
    display: inline;
    color: rgb(2, 63, 63);
}

.ExcellentText{
    display: inline;
    color: rgb(39, 5, 44);
}

.hoverText{
    background-color: whitesmoke;

    border: 2px solid black;
    border-radius: 15px;

    width: max-content;
    padding: 5px;
    font-size: 1.2rem;

    position: absolute;
    display: inline;

    z-index: 1;

    pointer-events: none;

    transition-duration: 0.5s;
}

.reveal{
    opacity: 1;
}

.hide{
    opacity: 0;
}