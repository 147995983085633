.ranked-overview-text{
    text-decoration: underline;
}

.overview-container{
    padding-top: 1em;
    border-top: 2px solid var(--blue-div-border-color);
    font-size: 3rem;
    color: var(--league-white-color);
    text-shadow: 0 0 2px black, 0 0 2px black;

    width: 100%;
    height: 100%;
}

.win-loss{
    margin: auto;
    margin-top: 1em;

    padding: 0.25em 0;

    width: 80%;
    max-width: 15ch;

    border: 2px solid var(--blue-div-border-color);
    background-color: var(--background-blue-div-color);
    border-radius: 25px;
}

.win-loss div{
    font-size: 2.5rem;
    width: 50%;
    display: inline-block;
}

.win-text{
    color: rgb(0, 128, 0);
}

.lose-text{
    color: rgba(255, 0, 0, 0.539);
}

.win-loss-number{
    color: white;
}

.recent-games{
    margin: auto;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;

    max-width: 50em;

    gap: 0.75em;
}

@media screen and (min-width: 75em){
    .overview-container{
        border-top: none;
    }

    .recent-games{
        padding: 1em 2em;
        border: 2px solid var(--blue-div-border-color);
        border-radius: 25px;
        background-color: var(--background-blue-div-color);
        margin-bottom: 0em;
    }
}