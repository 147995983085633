.card{
    width: 90%;
    max-width: 250px;

    border: 2px solid var(--league-gold-color);

    border-radius: 15px;
}

.blue-team{
    background-color: #020e1e7c;
}

.red-team{
    background-color: #210d0d7c;
}

.card .namePlate{
    height: 5.5rem;
    width: 100%;
    border-bottom: 2px solid var(--league-gold-color);

    padding: 0.5em 0em;

    border-radius: 15px;

    position: relative;
}

.card .namePlate .name{
    font-size: 2rem;

    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);

    white-space: nowrap;

    transition-duration: 0.25s;

    color: var(--league-white-color);
    text-shadow: 0 0 2px black, 0 0 2px black;

    z-index: 1;
}

.card .namePlate .name:hover{
    font-size: 2.1rem;
    color: var(--league-gold-color);
}

a:link {
    text-decoration: none;
}

.card .namePlate .name.highlight{
    color: var(--league-gold-color);
}

.card .namePlate .card-rank{
    width: 25%;
    position: absolute;
    left: -5%;
    top: -50%;
}

.card .namePlate .namePlate-img{
    width: 25%;

    position: absolute;
    top: 65%;
    left: 50%;
    transform: translateX(-50%);

    border-radius: 50%;
    border: 2px solid var(--league-gold-color);
    
}

.card .played-role{
    padding: 0.25em;
    margin: 5em auto;
    margin-bottom: 0px;

    width: fit-content;

    border: 1px solid var(--league-gold-color);
    border-radius: 15px;
}

.card .played-role .role-text{
    width: 100%;
    font-size: 1.2rem;

    border-bottom: 1px solid var(--league-gold-color);
    color: var(--league-gold-color);
}

.card .played-role img{
    width: 40%;
}

.card .card-badge-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1em;

    width: 80%;

    margin: 1em auto;
}

.card .card-badge-container .card-badge{
    margin: auto;
    width: 85%;
    aspect-ratio: 1/1;
}

/* .card {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.name{
    font-size: 75%;
    font-weight: bold;
    transition-duration: 0.25s;
    display: inline-block;
    position: relative;
    top: 10%;
    word-break: break-all;

    z-index: 1;
}

.name:hover{
    font-size: 80%;
    color: #bf8600;
}

.namePlate{
    width: 96%;
    height: 15%;
    padding: 2.5%;
    position: relative;
    border-bottom: 3px solid #bf8600;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.nameplate-img{
    background-color: rgba(255, 255, 255, 0.047);
    border: 2px solid #bf8600;
    width: 25%;
    height: 70%;
    border-radius: 50%;
    left: 50%;
    top: 115%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.highlightSummoner{
    color: #bf8600;
    text-shadow: 0 0 3px black, 0 0 3px black;
}

.lobbyParticipant{
    color: rgb(255, 255, 255);
    text-shadow: 0 0 3px black, 0 0 3px black;
}

a:link {
    text-decoration: underline;
    text-decoration-style: solid;
}

.played-role{
    position: relative;
    margin-top: 15%;
    width: 50%;
    box-sizing: border-box;
    height: 15%;
    border: 2px solid #553b007c;
    border-radius: 15px;
    overflow: hidden;
    text-shadow: 0 0 2px black, 0 0 2px black;
}

.roleIcon{
    aspect-ratio: 1/1;
    height: 50%;
}

.role-text{
    overflow: hidden;
    padding-top: 2%;
    height: 33%;
    font-size: 12px;
    color: #765300;
    border-bottom: 1px solid #553b007c;
}

.grid-container{
    height: 50%;
    padding-top: 5%;
    width: 100%;
    box-sizing: border-box;
}

.card-badges{
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
}

.badge-place{
    margin: 10%;
    width: 75%;
    aspect-ratio: 1 / 1;
}

.card-rank{
    position: absolute;
    width: 25%;

    right: 83%;
    bottom: 20%;

    z-index: 0;
} */