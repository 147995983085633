.chart-container{
    display: none;
}

.chart-tooltip{
    --tooltip-color: rgba(0, 255, 255, 0.214);
    --tooltip-hover-color: rgba(0, 255, 255, 0.658);

    position: absolute;
    left: 10%;
    top: 10%;

    width: 7%;
    aspect-ratio: 1/1;

    border: 2px solid var(--tooltip-color);  
    border-radius: 50%;

    transition: 0.1s border-color ease-out;

}

.chart-tooltip .chartTool-icon{
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    color: var(--tooltip-color);

    font-size: 1.6rem;

    transition: 0.1s color ease-out;

    pointer-events: none;
}

.chart-tooltip:hover{
    border-color: var(--tooltip-hover-color);
}

.chart-tooltip::after{
    content: attr(data-tooltip);

    visibility: hidden;
    opacity: 0%;

    position: absolute;
    left: 0;
    top: 120%;

    white-space: wrap;
    width: 40ch;

    padding: 1rem 2rem;
    background-color: #031328;

    border: 2px solid rgba(0, 0, 0, 0.666);
    border-radius: 10px;

    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.557);
    text-shadow: 0 0 2px black, 0 0 2px black;

    transition-duration: 0.5s;

    pointer-events: none;
}

.chart-tooltip:hover::after{
    visibility: visible;
    opacity: 100%;
    top: 150%;
}

.chart-tooltip:hover .chartTool-icon{
    color: var(--tooltip-hover-color);
}

.champion-chart{
    min-width: 350px;
    width: 100%;
    aspect-ratio: 1/1;
}

.champion-legend{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.champion-legend img{
    border-radius: 50%;
    max-width: 20%;
}

@media screen and (min-width: 75em){
    .chart-container{
        display: block;
    }
}