html{
  --league-gold-color: #bf8600;
  --league-blue-color: #071b30;
  --league-white-color: rgb(200, 200, 200);
  --background-blue-div-color: #020e1e3c;
  --blue-div-border-color: #020e1e;

  --excellent-color: rgb(60, 7, 68);
  --great-color: rgb(2, 106, 106);
  --good-color: rgba(64, 73, 70, 0.512);
  
  font-family: 'Kanit', sans-serif;
  font-size: 62.5%;
}

body{
  background-image: linear-gradient(to bottom right, #071b30, #0c2e54, #071b30);
  background-size: 200%;

  animation: bg-animation 10s infinite alternate;
}

@keyframes bg-animation{
  0% {
      background-position: left;
  }
  100% {
      background-position: right;
  }
}

.App {
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  height: 100vh;
}

.ping-container{
  font-size: 4rem;
  margin: auto;

  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.ping-container .pinging-server{
  color: var(--league-gold-color);
  text-shadow: 0 0 2px black, 0 0 2px black;
}

.ping-container .ping-gif{
  margin-top: 2rem;
  width: 5rem;
}

.server-down{
  margin: auto;

  width: 50%;

  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%);

  font-size: 4rem;

  text-align: center;

  color: var(--league-gold-color);
  text-shadow: 0 0 2px black, 0 0 2px black;
}

.server-down .offline-text{
  color: rgb(114, 1, 1);
  text-shadow: 0 0 2px black, 0 0 2px black;
}