.ranked-game{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5em 0;

    border-top: 2px solid;
    border-bottom: 2px solid;

    flex-wrap: wrap;
}

.won-game{
    border-color: green;
    background-color: rgba(0, 128, 0, 0.06);
}

.lost-game{
    border-color: red;
    background-color: rgba(255, 0, 0, 0.06);
}

.champ-sums-container{
    display: flex;
    font-size: 0rem;

    width: 20%;
    max-width: 125px;

    position: relative;
}

.champ-sums-container .champ-img-wrapper{
    width: 100%;
}

.champ-sums-container .champ-img-wrapper img{
    width: 100%;
    border-radius: 50%;
    border: 2px groove var(--league-white-color);
    box-sizing: border-box;
}

.champ-sums-container .spells-wrapper{
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    text-align: center;
}

.spells-wrapper .spell{
    position: relative;
    right: 50%;
    transform: scale(80%);
    width: 100%;
}

.spell img{
    border-radius: 10px;
    width: 100%;

    border: 1px solid var(--league-gold-color);
    box-sizing: border-box;
}

.kda{
    color: var(--league-white-color);
    font-size: 1.6rem;
    margin-left: 0.5em;

    min-width: 7.5rem;
}

.player-items{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    width: 30%;
    max-width: 20rem;

    gap: 0.75rem;

    font-size: 0;
}

.player-items .player-item{
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.221);
    width: 100%;
}

.player-items .player-item img{
    width: 100%;
}

.ranked-game-button{
    --button-color: rgba(255, 255, 255, 0.186);

    font-size: 1.5rem;

    background-color: rgba(0, 0, 0, 0.069);

    border-color: var(--button-color);
    border-radius: 50%;

    color: var(--button-color);

    transition-duration: .25s;
}

.more-info-box{
    margin: auto;
    max-width: 90%;

    font-size: 1.6rem;

    max-height: 0;
    transition: max-height 1s ease-in-out,
                padding 1s ease-in-out;
    overflow: hidden;

    border-bottom: 2px solid;
    border-left: 2px solid;
    border-right: 2px solid;

    border-radius: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

}

.more-info-stat{
    --stat-box-color: rgba(192, 192, 192, 0.775);
    border: 1px solid var(--stat-box-color);
    border-radius: 30px;

    background-color: rgba(0, 0, 0, 0.148);

    width: 40%;
    font-size: 1.4rem;

    padding: 0.5em 0.25em;

    position: relative;
}

.more-info-stat::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 98%;
    border-bottom: 1px solid var(--stat-box-color);
}

.more-info-stat .stat-description{
    padding: 0.2em 0;
    display: block;
}

.more-info-stat .stat{
    padding: 0.2em 0;
    display: block;
}

.stats-wrapper{
    display: flex;
    flex-wrap: wrap;

    gap: 1em;
    
    align-items: center;
    justify-content: center;

    color: var(--league-white-color);
}

.more-info-box .game-info-button{
    margin-bottom: 1.5em;
    padding: 0.25em 0.5em;
    font-size: 1.4rem;

    border-radius: 25px;
    border-width: 1px;

    background-color: rgba(192, 192, 192, 0.464);

    transition-duration: 0.25s;
}

.more-info-box .game-info-button:hover{
    background-color: rgba(192, 192, 192, 0.714);
}

@media screen and (min-width: 75em){
    .game-container{
        min-width: 260px;
    }
    .ranked-game.won-game{
        border-left: 2px solid green;
        border-right: 2px solid green;
        border-radius: 25px;
    }

    .ranked-game.lost-game{
        border-left: 2px solid red;
        border-right: 2px solid red;
        border-radius: 25px;
    }

    .kda{
        font-size: 2rem;
    }

    .ranked-game-button{
        font-size: 1.7rem;
    }

    .stats-wrapper{
        flex-direction: row;
        flex-wrap: wrap;

        gap: 2em;
    }

    .more-info-stat{
        width: 40%;
        font-size: 1.6rem;
    }
}