h1{
    font-size: 3rem;
    color: var(--league-gold-color);
    text-shadow: 0 0 3px black, 0 0 3px black;

    white-space: nowrap;
}

.help-container{
    display: flex;
    flex-direction: column;
    gap: 2em;

    margin-bottom: 1em;

    font-size: 1.6rem;
}

.help-container .help-item:nth-child(odd){
    background-color: #020e1e88;
}

.help-container .help-item{
    border: 3px solid var(--blue-div-border-color);
    background-color: var(--background-blue-div-color);
    border-left: none;
    border-right: none;

    padding: 2em 1em;
}

.help-container .help-item p{
    color: var(--league-white-color);
    text-shadow: 0 0 4px black, 0 0 4px black;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.help-container .help-item .help-img{
    width: max(20rem, 90%);
    border-radius: 25px;

    box-sizing: border-box;
    border: 1px solid rgb(73, 107, 144);
}

@media screen and (min-width: 65em) {
    h1{
        font-size: 4rem;
    }

    .help-page-container{
        position: absolute;
        top: 40%;
        transform: translateY(-40%);
    }
    
    .help-container{
        margin: auto;
        flex-direction: row;
        font-size: 2rem;

        justify-content: center;
    }

    .help-container > * {
        max-width: 25%;
    }

    .help-container .help-item{
        border: 3px solid #020e1e;
        border-radius: 35px;
    }
}