.player-name{
    font-size: 2.5rem;

    color: var(--league-gold-color);
    text-shadow: 0 0 3px black, 0 0 3px black;

    margin-top: 1.5em;

    text-decoration: underline;
    text-underline-offset: 0.5rem;
}

.player-container{
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;

    flex-wrap: wrap;
    gap: 2em;
}

.player-section{
    max-width: 100%;
}

.player-rank-piece{
    max-width: 15rem;

    margin: auto;
    margin-top: 1em;
}

.active-game-button{
    font-size: 1.6rem;

    padding: 0.25em 0.5em;
    border-radius: 10px;

    background-color: #00000023;
    color: var(--league-gold-color);
    border-color: var(--league-gold-color);

    transition-duration: 0.5s;
}

.no-player-data{
    font-size: 2rem;
    color: var(--league-white-color);

    display: inline-block;
    margin-top: 3em;
}

.active-game-button:hover{
    background-color: var(--league-gold-color);
    color: black;
    cursor: pointer;
}

@media screen and (min-width: 75em){
    .player-name{
        margin-top: 1em;
    }
    
    .player-container{
        flex-direction: row;
    }

    .player-section{   
        padding: 2em 3em;
        width: 25%;
    }

    .player-section:nth-child(1){
        position: fixed;
        right: 95%;
        top: 50%;
        transform: translate(95%, -50%);
    }

    .player-section:nth-child(2){
        width: 30%;
    }

    .player-section:nth-child(3){
        position: fixed;
        left: 95%;
        top: 50%;
        transform: translate(-95%, -50%);
        border: none;
    }

    .player-name{
        font-size: 4rem
    }

    .player-rank-piece{
        max-width: 30rem;
    }

    .no-player-data{
        font-size: 3rem;
        
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
    }
}