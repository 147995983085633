.game-view-container{
    display: flex;
    height: 100%;

    flex-wrap: wrap;

    gap: 5em;
}

.game-view-team{
    margin: auto;

    display: flex;
    flex-wrap: wrap;

    gap: 2em;
}

.game-view-team .game-view-player{
    font-size: 2.5rem;
    color: var(--league-white-color);
    text-shadow: 0px 0px 2px black;
}

.searched-player{
    font-size: 2.5rem;
    color: var(--league-gold-color);
    text-shadow: 0px 0px 2px black;
}

.compare-box{
    width: 100%;
    max-width: 1200px;
    background-color: rgba(27, 103, 170, 0.098);
    margin: 2.5% 0;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    gap: 3em;

    border: 1px solid rgba(255, 255, 255, 0.082);
}

.compare-box .compare-player .compare-player-name{
    font-size: 2rem;
    color: var(--league-white-color);

    text-decoration: underline;

    display: block;
    width: fit-content;
    margin: auto;

    transition-duration: 0.25s;
}

.compare-box .compare-player .searched-player{
    text-decoration: underline;

    display: block;
    width: fit-content;
    margin: auto;

    transition-duration: 0.25s;
}

.compare-box .compare-player .searched-player:hover{
    color: var(--league-white-color);
}


.compare-box .compare-player .compare-player-name:hover{
    color: var(--league-gold-color);
}

.compare-box .compare-player img{
    width: 15%;
    border-radius: 50%;
    border: 1px solid var(--league-white-color);
}

.compare-box .compare-player .compare-stats-container{
    padding: 2em 0;
    display: flex;
    flex-wrap: wrap;

    justify-content: center;

    gap: 4em;
}

.compare-box .compare-player .compare-stats-container .compare-stats{
    font-size: 1.6rem;

    color: rgb(46, 123, 146);
}

.compare-box .compare-player .compare-stats-container .compare-stats .stat-name{
    text-decoration: underline;
}

.compare-box .compare-player .compare-stats-container .compare-stats .stat-number{
    color: var(--league-white-color);
}

.player-game-wrapper{
    cursor: pointer;
}

.player-game-wrapper.selected-player{
    background-color: #00000053;
}

@media screen and (min-width: 75em){
    .game-view-team{
        max-width: 20%;
    }

    .player-game-wrapper.selected-player{
        border-radius: 25px;
    }

    .compare-box{
        width: 30%;
        border-radius: 25px;
    }

    .compare-box .compare-player .compare-player-name{
        font-size: 3rem;
    }

    .compare-box .compare-player .searched-player{
        font-size: 3rem;
    }
}