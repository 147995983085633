.team-container{
    margin: 4em auto;
    display: flex;
    flex-wrap: wrap;
    gap: 8em;
    justify-content: center;
}

.versusTag{
    font-size: 3rem;
    color: var(--league-gold-color);
    text-shadow: 0 0 3px black, 0 0 3px black;
}