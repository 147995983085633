.text{
    font-size: 2.5rem;
    color: var(--league-gold-color);
}

.button{
    font-size: 1.6rem;
    padding: 0.5em 0.5em;
    border-radius: 10px;
    background-color: #00000023;
    color: var(--league-gold-color);
    transition-duration: 0.5s;
    border-color: var(--league-gold-color);
    white-space: nowrap;
}

.button:hover{
    background-color: var(--league-gold-color);
    color: black;
    cursor: pointer;
}

.center{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
}

@media screen and (min-width: 65em){
    .text{
        font-size: 4rem;
    }

    .button{
        font-size: 2.5rem;
    }
}