.loading-container {
    position: absolute; 
    top: 45%;
    left: 50%; 
    transform: translate(-50%, -50%);

    width: 100%;
    max-width: 400px;
    text-align: center;
}

.loading-container img{
    width: 80%;
    margin: auto;
}

.loading-bar {
    margin-left: auto; 
    margin-right: auto; 

    position: absolute; 
    left: 50%; 
    top: 75%; 
    transform: translate(-50%); 

    border: 3px solid var(--league-gold-color);
    border-radius: 25px;

    width: 90%;
    height: 10%;
    min-height: 20px;
}

.loading-bar::before{
    content: '';
    display: block;
    position: absolute;
    height: 60%;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);

    width: calc(var(--load-percent, 0) * 1%);
    border-radius: 25px;
    min-width: 5%;
    max-width: 96%;

    background-color: var(--league-gold-color);
    
    transition-duration: 1s;
}