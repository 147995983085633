.front-page{
    max-width: 100%;
    min-height: 100%;
}

.form-container{
    position: relative;
    top: 30%;
    transform: translateY(-30%);
}

.form-background{
    margin: auto;
    
    width: min(90%, 60rem);
    aspect-ratio: 1/.60;

    border-radius: 50%;
    border: 1px solid var(--league-gold-color);
    overflow: hidden;

    margin-bottom: 2em;
}

.form-background img{
    width: 115%;
    height: 115%;
    
    border: 1px solid var(--league-white-color);

    pointer-events: none;
}

.form-container form label{
    font-size: 2.5rem;
    color: var(--league-gold-color);
    text-shadow: 0 0 2px black, 0 0 2px black;
}

.form-container form .input-box{
    margin: auto;

    background-color: rgba(255, 255, 255, 0.7);

    box-sizing: border-box;

    width: min(90%, 45ch);
    
    margin-top: 1.5em;
    margin-bottom: 2em;
    
}

.form-container form .input-box:focus-within{
    outline: 2px solid #165496ca;
}

.form-container form .input-box .text-input{
    display: inline-block;
    width: 80%;

    font-size: 2rem;

    box-sizing: border-box;

    border: none;

    text-align: center;

    background: none;
}

.form-container form .input-box .text-input:focus{
    outline: none;
}

.form-container form .input-box select{
    width: 20%;

    text-align: center;

    appearance: none;
    text-indent: 1px;
    text-overflow: '';

    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.4);

    background-color: rgba(0, 0, 0, 0.25);

    font-family: inherit;
    font-size: 2rem;

    transition-duration: 0.25s;
}

form .input-box select:focus{
    outline: none;
}

.form-container form .button{
    margin: auto;

    max-width: 50%;

    padding: 0.5em 0.5em;

    font-size: 1.4rem;
    
    background-color: #071b30e1;
    color: var(--league-gold-color);
    text-shadow: 0 0 2px black, 0 0 2px black;

    border-color: var(--league-gold-color);
    border-radius: 15px;

    transition-duration: 0.5s;
}

.loadingGif{
    position: absolute;
    top: 95%;
    transform: translate(-50%, -95%);
    
    width: 5rem;
}

form .input-box select:hover{
    background-color: rgba(0, 0, 0, 0.25);
}

.form-container form .button:hover{
    background-color: var(--league-gold-color);
    text-shadow: 0 0 1px black, 0 0 1px black;
    text-shadow: none;
    color: #071b30;
    cursor: pointer;
}

@media screen and (min-width: 65em){
    .form-container{
        top: 50%;
        transform: translateY(-50%);
    }

    .form-container .form-background{
        width: max(60%, 80rem);
    }

    .form-container form{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: max(100%, );
    }

    .form-container form .input-box{
        width: 55ch;
    }

    .form-container form label{
        font-size: 3.5rem;
    }

    .form-container form .button{
        font-size: 1.6rem;
    }

    .form-container form::before{
        content: '';

        display: block;
        position: absolute;
        z-index: -1;

        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        width: 115%;
        aspect-ratio: 1/1;

        background-color: var(--league-blue-color);
        opacity: 85%;
        border-radius: 50%;

    }

    .loadingGif{
        top: 65%;
        left: 50%;
        transform: translate(-50%, -65%);
    }
}